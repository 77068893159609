<template>
  <div class="policyMainDiv">
    <div class="policyMainTitle">Terms & Conditions</div>
    <div v-if="defaltText == ''">
      <p class="policyMainTitleDetail">
        Welcome to VUE Collection. These terms and conditions outline the rules
        and regulations for the use of VUE LS CORP's website:
        http://www.vuecollection.com. We are a registered company in the USA,
        registration #825016456. By accessing this website, we assume you accept
        these terms and conditions. Do not continue to use VUE Collection if you
        do not agree to take all of the terms and conditions stated on this
        page. All reservations and/or purchases you make through us are subject
        to the terms, conditions, and notices as they exist at the time the
        reservation is made. We reserve the right to change these terms,
        conditions, and notices, and you agree to accept and be bound by such
        terms, conditions, and notices stated here.
      </p>
      <div class="policySubTitle">Our Pledge to You</div>
      <div class="policySubDetail">
        Vuecollection.com will be responsible for the proper provision of all
        travel services included in your booking, as set out in your
        confirmation. Subject to these Booking Conditions, if we perform or
        arrange these services and we do not fulfill them or resolve your
        complaint within a reasonable period, you may be entitled to an
        appropriate price reduction or compensation or both. You must inform us
        without undue delay of any failure to perform or improper performance of
        the travel services included in this booking. The level of any such
        price reduction or compensation will be calculated taking into
        consideration all relevant factors, such as following the complaints
        procedure as described in the booking conditions. Please note that it is
        your responsibility to show that we have been negligent if you wish to
        make a claim against us.
      </div>
      <!-- <div class="policySubTitle">Our Pledge to Travel Agents</div>
      <div class="policySubDetail">
        VUE Collection will be responsible for the proper provision of all travel
        services included in your booking, as set out in your client’s
        confirmation. This includes hotel accommodation & domestic transfers.
        Activities & excursions can be arranged through VUE Collection but payable
        at the resort as they may be handled by a third-party vendor. We strive
        to provide the best resort rates for your clients and provide
        commission, where applicable. Examples such as taxes, green tax, service
        charges and items such as compulsory transfers are non-commissionable.
        The type of payment will affect the commission structure. Please contact
        us for further details hello@vuelifestyle.com
      </div>
      <div class="policySubTitle">Your Promise to Us</div>
      <div class="policySubDetail">
        You are at least 18 years old and possess the legal authority to enter
        into this agreement and to use this website in accordance with the terms
        and conditions of this agreement. You also agree to be financially
        liable for all uses of this website, as well as for the use of your name
        and credit card information to pay for all things purchased at this
        website by members of your household, including minors living with you.
        You also agree that all information supplied by you or members of your
        household while using this website is true and accurate. It is your
        responsibility to ensure that you and all persons traveling with you
        have valid passports (with at least 6 months validity beyond the date of
        return and at least two blank pages or more), appropriate visas and
        vaccinations.
      </div>
      <div class="policySubTitle">Credit Card Fees and Bank Fees</div>
      <div class="policySubDetail">
        Credit card fees & bank transfers are processed in the USA. Some banks
        and credit card companies impose fees for international or cross border
        transactions. For example, if you are using a non USA-issued card to
        charge payment through us, your bank may charge you an international
        transaction fee. In addition, some banks and card companies impose fees
        for currency conversion. For instance, if you are making a booking in a
        currency other than the currency of your credit card, your credit card
        company may convert the booking amount to the currency of your credit
        card and charge you a conversion fee. The currency exchange rate and
        foreign transaction fee is determined solely by your bank on the day
        that they process the transaction. If you have any questions about these
        fees or the exchange rate applied to your booking, please contact your
        bank.
      </div>
      <div class="policySubTitle">Insurance</div>
      <div class="policySubDetail">
        Travel insurance is strongly recommended. You should be fully insured
        for your holiday and make sure that all the activities which you will be
        carrying out are covered under such insurance, protecting against
        unforeseen circumstances that could otherwise spoil your travels, as
        this is not covered booking through us. Cover should also include
        medical expenses and repatriation in the event of accident or illness.
        In addition, we strongly recommend that you have cover for personal
        belongings, delay at your outward or homeward point of departure,
        personal liability, overseas legal expenses, and cancellation.
      </div>
      <div class="policySubTitle">Privacy</div>
      <div class="policySubDetail">
        Personal information that you provide when using our website and booking
        holidays will be treated in accordance with our Privacy Policy on our
        website.
      </div>
      <div class="policySubTitle">Governing Law</div>
      <div class="policySubDetail">
        These terms and conditions and any disputes arising from them shall be
        governed by New York State Law. You and we agree to submit to the
        exclusive jurisdiction of the courts of USA regarding any such dispute.
      </div>
      <div class="policySubTitle">Changes to Terms and Conditions</div>
      <div class="policySubDetail" style="margin-bottom: 200px">
        The terms and conditions which apply to your holiday are those which are
        featured on your booking form at the time of booking, and any other
        terms notified to you in writing before departure. We reserve the right
        to update these terms and conditions from time to time. Any such updates
        shall take effect immediately upon posting on our website. Should you
        have any question, please contact hello@vuelifestyle.com
      </div> -->
    </div>
    <div v-else class="policySubDetail">
      {{ defaltText }}
    </div>
  </div>
</template>
<script>
import { api } from "../helpers/Helpers";
export default {
  data() {
    return {
      defaltText: "",
      hotelId: "",
    };
  },
  created() {
    // if (this.$route.query.textDate != undefined){
    //   this.defaltText = this.$route.query.textDate;
    // }
    if (this.$route.query.hotelId != undefined) {
      this.hotelId = this.$route.query.hotelId;
      this.initHotel();
    }
  },
  methods: {
    initHotel: async function () {
      const res = await api.getHotelById(this.hotelId);
      if (
        res != undefined &&
        res.promotion != undefined &&
        res.promotion.length > 0
      ) {
        this.defaltText = res.promotion[0].termsconditions;
      }
    },
  },
};
</script>
